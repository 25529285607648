import { configureStore } from '@reduxjs/toolkit'
import headerSlice from '../features/common/headerSlice'
import modalSlice from '../features/common/modalSlice'
import userSlice from '../slices/users'
import manualSlice from '../slices/manuals'

const combinedReducer = {
  header : headerSlice,
  modal : modalSlice,
  users : userSlice,
  manuals : manualSlice,
}

export default configureStore({
  reducer: combinedReducer
});