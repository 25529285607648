import React, { useEffect, useState } from "react";
import axios from "axios";
// import { toast } from 'react-toastify';
import { AuthContext } from "./AuthContext";
import { useLocalStorage } from "../hooks/useLocalStorage";
import Config from '../config/config';
// import browserSignature from 'browser-signature/dist/storage';


// https://dev.to/joseprest/the-advanced-guide-to-react-context-with-hooks-dh9

export function AuthProvider({ children }) {

  const USER_KEY = 'USER-KEY';
  const [ user, setUser ] = useState(null);
  const [ showMenu, setShowMenu ] = useState(false);
  const [ showStats, setShowStats ] = useState(false);
  const { getItem, setItem } = useLocalStorage();

  useEffect(() => {
    const user = getItem(USER_KEY);

    if (user && user !== 'null') {
      let parsedUser = JSON.parse(user);
      parsedUser.environment = process.env.REACT_APP_ENVIRONMENT;
      setUser(parsedUser);
    } else {
      // const signature = browserSignature();
      // setUser({
      //   id: signature,
      //   environment: process.env.REACT_APP_ENVIRONMENT,
      //   email: 'anonymous@agilmenteapp.com',
      //   token: 'ANONYMOUS-' + signature + '-ANONYMOUS',
      //   name: 'Anonimo'
      // });
    }
  }, []);


  return (
    <AuthContext.Provider
      value={{
        login: (data) => {
          return new Promise((resolve, reject) => {
            axios({
              method: 'POST',
              url: Config.API.BASE_URI + 'users/login',
              data: data
            }).then(response => {
              setItem(USER_KEY, JSON.stringify(response.data));
              setUser(response.data);
              resolve();
            }).catch( err => {
              console.log(err);
              reject();
            });
          });
        },

        logout: () => {
          return new Promise((resolve, reject) => {
            setItem(USER_KEY, null);
            setUser(null);
            resolve();
          });
        },

        register: (data) => {
          return new Promise((resolve, reject) => {
            axios({
              method: 'POST',
              url: Config.API.BASE_URI + 'users/register',
              data: data
            }).then(response => {
              setItem(USER_KEY, JSON.stringify(response.data));
              setUser(response.data);
              // toast.success(<div>El usuario se registró correctamente</div>);
              resolve();
            }).catch( err => {
              // toast.error(<div>{ err.response.data.message }</div>);
              reject();
            });
          });
        },

        forgotPassword: (data) => {
          return new Promise((resolve, reject) => {
            axios({
              method: 'POST',
              url: Config.API.BASE_URI + 'users/forgotPassword',
              data: data
            }).then(response => {
              // toast.success(<div>El usuario se registró correctamente</div>);
              resolve();
            }).catch( err => {
              // toast.error(<div>{ err.response.data.message }</div>);
              reject();
            });
          });
        },

        isAuthenticated: false,
        user: user,

        setShowMenu: setShowMenu,
        showMenu: showMenu,

        setShowStats: setShowStats,
        showStats: showStats,

      }}
    >
      {children}
    </AuthContext.Provider>
  );
}