import React, { lazy, useEffect } from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import initializeApp from './app/init';
import useAuthentication from './auth/useAuthentication';
import Editor from './pages/protected/Editor';
import axios from "axios";


// Importing pages
const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const Register = lazy(() => import('./pages/Register'))


// Initializing different libraries
// initializeApp()



function App() {
  const context = useAuthentication();

  useEffect(() => {
    if (context.user && context.user.token) {
      axios.defaults.headers.common['x-access-token'] = context.user?.token;
    }
  }, [context.user]);

  return (
    <>
      <Router>
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/register' element={<Register />} />
          <Route path='/editor' element={<Editor />} />

          {/* Place new routes over this */}
          <Route path='/app/*' element={<Layout />} />

          <Route path='*' element={<Navigate to={context.user && context.user.token ? '/app/manuals' : '/login'} replace />}/>

        </Routes>
      </Router>
    </>
  )
}

export default App
