import config from "../config/config";
import axios from "axios";

/*

const Api = () => {
  // const [value, setValue] = useState(null);

  // const setItem = (key, value) => {
  //   console.log(key, value);
  //   localStorage.setItem(key, value);
  //   setValue(value);
  // };

  // const getItem = (key) => {
  //   const value = localStorage.getItem(key);
  //   setValue(value);
  //   return value;
  // };

  // const removeItem = (key) => {
  //   localStorage.removeItem(key);
  //   setValue(null);
  // };
  const apiGet = async (path, page) => {
    return axios({
      method: 'GET',
      url: config.API.BASE_URI + path + '?_page=' + page,
      // url: config.API.BASE_URI + 'users?_page=1',
    }).then(response => response
    ).catch(err => err);
    // const { get, post, updating } = useApi();

    // return get('users', { page: 1 }).then(r => {
    //   console.log(r);
    //   return r.data;
    //   // setData(r.data);
    // })

  };


  const apiPost = async (path, data) => {
    return axios({
      method: 'POST',
      url: config.API.BASE_URI + path,
      data: data,
    }).then(response => response.data
    ).catch(err => err);
    // const { get, post, updating } = useApi();

    // return get('users', { page: 1 }).then(r => {
    //   console.log(r);
    //   return r.data;
    //   // setData(r.data);
    // })

  };

  // return { value, setItem, getItem, removeItem };
  return { apiGet, apiPost };
};
*/

// export default Api;
export default {



  apiGet: async (path, page) => {
    return axios({
      method: 'GET',
      url: config.API.BASE_URI + path + '?_page=' + page,
      // url: config.API.BASE_URI + 'users?_page=1',
    }).then(response => response
    ).catch(err => err);
    // const { get, post, updating } = useApi();

    // return get('users', { page: 1 }).then(r => {
    //   console.log(r);
    //   return r.data;
    //   // setData(r.data);
    // })

  },

  apiPost: async (path, data) => {
    return axios({
      method: 'POST',
      url: config.API.BASE_URI + path,
      data: data,
    }).then(response => response.data
    ).catch(err => err);
    // const { get, post, updating } = useApi();

    // return get('users', { page: 1 }).then(r => {
    //   console.log(r);
    //   return r.data;
    //   // setData(r.data);
    // })

  },

  apiDelete: async (path) => {
    return axios({
      method: 'DELETE',
      url: config.API.BASE_URI + path,
      // url: config.API.BASE_URI + 'users?_page=1',
    }).then(response => response
    ).catch(err => err);
    // const { get, post, updating } = useApi();

    // return get('users', { page: 1 }).then(r => {
    //   console.log(r);
    //   return r.data;
    //   // setData(r.data);
    // })

  },
}