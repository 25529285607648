const local = {
  API: {
    BASE_URI : 'http://localhost:8080/api/'
  },
  AUTH: {
    LOCAL_STORAGE_KEY: 'ambassador-local-auth-token'
  },
};

const prod = {
  API: {
    // BASE_URI : 'http://13.56.170.234/api/'
    BASE_URI : 'https://ambassador.pragmatia.com/api/'
    // BASE_URI : 'http://ambassador-env.eba-6wgnwq9b.us-west-1.elasticbeanstalk.com/api/'
  },
  AUTH: {
    LOCAL_STORAGE_KEY: 'ambassador-prod-auth-token'
  },
};


const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : local;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  pagination: {
    paginationPerPage: 10,
    progressPending: true,
    paginationComponentOptions: { noRowsPerPage: true },
  },

  ...config
};