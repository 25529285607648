import moment from 'moment';

export default {

  getSlug: (text, appendCurrentDate) => {
    return text.toLowerCase()
    .replace(/ /g, "-")
    .replace(/[^\w-]+/g, "") + (appendCurrentDate ? '_' + moment().format('YYYY-MM-DD-HH-mm') : '');
  },

}