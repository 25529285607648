import React, { useState, useEffect,useRef, useMemo } from 'react';
import JoditEditor, { Jodit, Dom }  from 'jodit-react';

import Api from '../../utils/Api'
import { saveAs } from "file-saver";
import moment from 'moment';
import { useParams } from 'react-router-dom';
import './Editor.scss'
import useAuthentication from '../../auth/useAuthentication';
import Slug from '../../utils/Slug';
import config from '../../config/config';
import axios from "axios";
import { nanoid } from 'nanoid'
import { toast } from 'react-toastify';

export default () => {
	const editor = useRef(null);
  const { ContextMenu } = Jodit.modules;

	const [manual, setManual] = useState(null);
	const [showBlocks, setShowBlocks] = useState(true);
  const context = useAuthentication();
  const { id } = useParams();

  useEffect(() => {
    if (context.user && context.user.token) {
      axios.defaults.headers.common['x-access-token'] = context.user?.token;
    }

  }, [context.user]);

  useEffect(() => {
    Api.apiGet('manuals/' + id).then(r => {
      if (r && r.data && r.data.text) {
        // r.data.text = r.data.text.replaceAll('custom-addendum-block', 'custom-addendum-block-hidden');
        console.log(r.data);
        setManual(r.data);
      }
    }).catch(err => {
      console.log('Api error:', err);
    });
  }, [ id ]);

  const hasClass = (target, className) => {
    return new RegExp('(\\s|^)' + className + '(\\s|$)').test(target.className);
  };

  const getRootElement = (element) => {
    if (hasClass(element.parentNode, 'jodit-wysiwyg')) {
      return element;
    } else {
      return getRootElement(element.parentNode)
    }
  };

  const contentUpdated = content => {
    console.log(content)
  };

  useEffect(() => {
    if (manual) {
      if (!showBlocks) {
        const elems = document.getElementsByClassName('custom-addendum-block');
        Array.from(elems).forEach((el) => {
          const id = el.getElementsByClassName('custom-addendum-block-id')[0];
          if (id) {
            id.classList.remove('custom-addendum-block-id')
            id.classList.add('custom-addendum-block-id-hidden')
          }

          el.classList.remove('custom-addendum-block');
          el.classList.add('custom-addendum-block-hidden');
        });
      } else {
        const elems = document.getElementsByClassName('custom-addendum-block-hidden');
        Array.from(elems).forEach((el) => {
          const id = el.getElementsByClassName('custom-addendum-block-id-hidden')[0];
          if (id) {
            id.classList.remove('custom-addendum-block-id-hidden')
            id.classList.add('custom-addendum-block-id')
          }

          el.classList.add('custom-addendum-block');
          el.classList.remove('custom-addendum-block-hidden');
        });
      }
    }
  }, [showBlocks, manual]);



  const toolbarButtons = [ {
      icon: 'angle-left',
      name: '< Back',
      tooltip: 'Back',
      exec(editor) {
        if (context.user && context.user.role && context.user.role == 'ADMIN') {
          if (window.confirm('Are you sure you want to go back? All unsaved changes will be lost.')) {
            window.location.href = '/app/manuals';
            return false;
          }
        } else {
          window.location.href = '/app/manuals';
          return false;
        }
      }
  } ];


  if (context.user && context.user.role && context.user.role == 'ADMIN') {

    // ...Jodit.defaultOptions.buttons,
    // toolbarButtons.push(..."left,center,right,justify,preview,bold,italic,underline,strikethrough,indent,outdent,eraser,copyformat,ul,ol,font,fontsize,paragraph,lineHeight,superscript,subscript,classSpan,image,source".split(','));
    // toolbarButtons.push(..."|,bold,italic,underline,strikethrough,|,left,center,right,justify,|,indent,outdent,|,eraser,copyformat,|,fontsize,lineHeight".split(','));
    toolbarButtons.push(..."|,undo,redo,|,bold,italic,underline,strikethrough,|,left,center,right,justify,|,indent,outdent,|,fontsize,lineHeight".split(','));

    toolbarButtons.push({
      name: 'customOrderedList',
      icon: 'ol',
      tooltip: 'Ordered List',
      list: {
        'lower-alpha': 'Lower Alpha (a, b, c)',
        'lower-roman': 'Lower Roman (i, ii, iii)',
        'decimal': 'Decimal (1, 2, 3)'
      },
      exec: (editor, current, control) => {
        let listType = 'lower-alpha';
        if (control && control.control && control.control.args && control.control.args[0]) {
          listType = control.control.args[0];
        }
        editor.execCommand('insertOrderedList');
        const sel = editor.selection.sel;
        if (sel && sel.rangeCount > 0) {
          let range = sel.getRangeAt(0);
          let selectedElement = range.commonAncestorContainer;

          let olElement = selectedElement.closest('ol');
          if (olElement) {
            olElement.style.listStyleType = listType;
          }
        }
      }
    });


    toolbarButtons.push({
      name: 'customFormattingList',
      icon: 'paragraph',
      tooltip: 'Define Formatting',
      list: {
        'my-h1': 'H1',
        'my-h2': 'H2',
        'my-h3': 'H3',
        'my-paragraph': 'Paragraph'
      },
      exec: (editor, current, control) => {
        let formattingType = 'my-paragraph';
        if (control && control.control && control.control.args && control.control.args[0]) {
          formattingType = control.control.args[0];
        }
        const sel = editor.selection.sel;
        if (sel && sel.rangeCount > 0) {
          if (formattingType == 'my-h1') {
            // editor.selection.commitStyle({ element: 'strong' }); // Wrap selected text in <h1> tag
            editor.selection.commitStyle({ element: 'h1' }); // Wrap selected text in <h1> tag
            let range = sel.getRangeAt(0);
            // let selectedContent = range.extractContents();
            // console.log(selectedContent)
            let selectedElement = range.commonAncestorContainer;

            // const strongElement = selectedElement.closest('strong');
            // strongElement.style.fontSize = '24px';
            const h1Element = selectedElement.closest('h1');
            const strong = document.createElement('strong');
            strong.style.fontSize = '24px';
              // and give it some content
            const newContent = document.createTextNode(selectedElement.textContent);
            // const newContent = document.createTextNode('');

            // add the text node to the newly created div
            strong.appendChild(newContent);


            selectedElement.appendChild(strong)
            h1Element.style.textAlign = 'center';
            // range.setStartAfter(range.startContainer);
            range.deleteContents();

            // h1Element.style.textTransform = 'capitalize';
            // h1Element.style.fontWeight = 'bold';
            // h1Element.style.fontSize = '24px';
            // h1Element.style.textDecoration = 'none';
            // h1Element.style.fontStyle = 'bold';
            // <h1 style="text-align: center;"><strong style="font-size: 24px;">Section 1. Ownership</strong></h1>

            // h1Element.appendChild(selectedContent);
          } else if (formattingType == 'my-h2') {
            // <div><strong><u>2-POSITION SCOPE</u></strong></div>
            editor.selection.commitStyle({ element: 'div' });
            let range = sel.getRangeAt(0);
            let selectedElement = range.commonAncestorContainer;
            const strong = document.createElement('strong');
            const u = document.createElement('u');
            const newContent = document.createTextNode(selectedElement.textContent);
            u.appendChild(newContent);
            strong.appendChild(u)
            selectedElement.appendChild(strong)
            range.deleteContents();



            // editor.selection.commitStyle({ element: 'h2' }); // Wrap selected text in <h1> tag
            // let range = sel.getRangeAt(0);
            // let selectedElement = range.commonAncestorContainer;
            // const h1Element = selectedElement.closest('h2');
            // h1Element.style.textAlign = 'left';
            // h1Element.style.textTransform = 'capitalize';
            // h1Element.style.fontWeight = 'bold';
            // h1Element.style.fontSize = '24px';
            // h1Element.style.textDecoration = 'underline';
            // h1Element.style.fontStyle = 'normal';
          } else if (formattingType == 'my-h3') {

            editor.selection.commitStyle({ element: 'div' });
            let range = sel.getRangeAt(0);
            let selectedElement = range.commonAncestorContainer;
            const strong = document.createElement('strong');
            const newContent = document.createTextNode(selectedElement.textContent);
            strong.appendChild(newContent)
            selectedElement.appendChild(strong)
            range.deleteContents();


            // editor.selection.commitStyle({ element: 'strong' }); // Wrap selected text in <h1> tag
            // editor.selection.commitStyle({ element: 'div' }); // Wrap selected text in <h1> tag
            // let range = sel.getRangeAt(0);
            // let selectedElement = range.commonAncestorContainer;
            // // const strongElement = selectedElement.closest('strong');
            // // strongElement.style.fontSize = '15px';
            // const h1Element = selectedElement.closest('h3');
            // h1Element.style.textAlign = 'left';
            // h1Element.style.fontSize = '20px';
            // h1Element.style.fontWeight = 'normal';
            // h1Element.style.textDecoration = 'none';
            // h1Element.style.fontStyle = 'normal';
          } else if (formattingType == 'my-paragraph') {
            // const h1Element = selectedElement.closest('p');
            const pElement = document.createElement('p');
            pElement.style.textAlign = 'left';
            pElement.style.fontSize = '20px';
            pElement.style.fontWeight = 'normal';
            pElement.style.textDecoration = 'none';
            pElement.style.fontStyle = 'italic';

            let range = sel.getRangeAt(0);

            // 3. Creamos el nuevo nodo con el texto seleccionado (por ejemplo, un <span> con estilo)
            let newNode = document.createElement("span");
            newNode.textContent = range.toString(); // Insertamos el texto seleccionado
            // newNode.style.backgroundColor = "yellow";  // Ejemplo de estilo (resaltado)

            // 4. Limpiamos la selección actual para evitar problemas de sobrescritura
            sel.removeAllRanges();

            // 5. Reemplazamos el rango seleccionado con el nuevo nodo
            range.deleteContents();   // Borramos el contenido seleccionado
            range.insertNode(newNode); // Insertamos el nuevo nodo

            // 6. Ajustamos el rango para que se sitúe después del nuevo nodo (opcional)
            range.setStartAfter(newNode);
            sel.addRange(range); // Seleccionamos el rango actualizado (opcional)

            /*
            let selectedElement = range.commonAncestorContainer;
            console.log('texto', range.toString())
            const newContent = document.createTextNode(range.toString());
            // const newContent = document.createTextNode('');

            // add the text node to the newly created div
            pElement.appendChild(newContent);


            selectedElement.appendChild(pElement)
            // h1Element.style.textAlign = 'center';
            // range.setStartAfter(range.startContainer);
            // range.deleteContents();

            let fragment = range.createContextualFragment('<p>'+range.toString()+'</p>');

            // var firstInsertedNode = fragment.firstChild;
            // var lastInsertedNode = fragment.lastChild;
            range.insertNode(fragment);
            // if (selectInserted) {
            //     if (firstInsertedNode) {
            //         range.setStartBefore(firstInsertedNode);
            //         range.setEndAfter(lastInsertedNode);
            //     }
            //     sel.removeAllRanges();
            //     sel.addRange(range);
            // }

            */


            // editor.selection.commitStyle({ element: 'p' }); // Wrap selected text in <h1> tag
            // let range = sel.getRangeAt(0);
            // let selectedElement = range.commonAncestorContainer;
            // selectedElement.textContent
            // const h1Element = selectedElement.closest('p');
            // h1Element.style.textAlign = 'left';
            // h1Element.style.fontSize = '20px';
            // h1Element.style.fontWeight = 'normal';
            // h1Element.style.textDecoration = 'none';
            // h1Element.style.fontStyle = 'italic';
          }
        }



        // editor.execCommand('insertOrderedList');
        // const sel = editor.selection.sel;
        // if (sel && sel.rangeCount > 0) {
        //   let range = sel.getRangeAt(0);
        //   let selectedElement = range.commonAncestorContainer;

        //   let olElement = selectedElement.closest('ol');
        //   if (olElement) {
        //     olElement.style.listStyleType = listType;
        //   }
        // }
      }
    });

    // toolbarButtons.push({
    //   name: 'customHeader',
    //   icon: 'paragraph',
    //   tooltip: 'Define Header',
    //   exec: (editor, current, control) => {
    //     const sel = editor.selection.sel;
    //     if (sel && sel.rangeCount > 0) {
    //       editor.selection.commitStyle({ element: 'strong' }); // Wrap selected text in <h1> tag
    //       editor.selection.commitStyle({ element: 'h1' }); // Wrap selected text in <h1> tag
    //       let range = sel.getRangeAt(0);
    //       let selectedElement = range.commonAncestorContainer;
    //       const strongElement = selectedElement.closest('strong');
    //       strongElement.style.fontSize = '24px';
    //       const h1Element = selectedElement.closest('h1');
    //       h1Element.style.textAlign = 'center';
    //     }
    //   }
    // });

    if (process.env.NODE_ENV == 'development' || (context.user && context.user.email && context.user.email == 'mradosta@pragmatia.com')) {
      toolbarButtons.push('|');
      toolbarButtons.push('source');
    }
    toolbarButtons.push('|');
    toolbarButtons.push('image');
    // toolbarButtons.push('search');
    toolbarButtons.push('|');

    // toolbarButtons.push({
    //   // iconURL: 'https://www.svgrepo.com/show/325174/eye-close.svg',
    //   name: 'Search',
    //   tooltip: 'Search',
    //   exec: (e, b, btn) => {
    //     e.e.dispatchEvent(new KeyboardEvent('keypress', {
    //       ctrlKey: true,
    //       key: 'f',
    //     }));

    //     // editor.e.fire('search', '').then(() => {
    //     //   console.log('Selected!');
    //     // });

    //   }
    // });

    toolbarButtons.push({
      iconURL: 'https://www.svgrepo.com/show/325174/eye-close.svg',
      name: 'Show Blocks',
      tooltip: 'Show Blocks',
      exec: (a, b, btn) => {
        setShowBlocks(!showBlocks);
      }
    });

    toolbarButtons.push({
      icon: 'eye',
      name: 'Hide Blocks',
      tooltip: 'Hide Blocks',
      exec: (editor, b, btn) => {
        setShowBlocks(!showBlocks);
      }
    });
    toolbarButtons.push('|');

    toolbarButtons.push({
      name: 'Save',
      tooltip: 'Save',
      exec(editor) {
        const currentText = editor.value;
        Api.apiPost('manuals', { id, text: currentText }).then(r => {
          toast.success(<div>Manual saved!</div>);
          // window.location.href = '/app/manuals'
        });
        return false;
      }
    });
  }

  toolbarButtons.push({
    name: 'PDF',
    tooltip: 'Create PDF',
    exec(editor) {
      toast.success('Generating PDF, please wait...', {
        autoClose: 5000,
        hideProgressBar: false,
        progress: undefined,
        // theme: "light",
        // transition: Bounce,
        });
      setTimeout(() => {
        const outputName = 'DLTPolicy Manual-' + Slug.getSlug(manual.name, true) + '-' + moment().format('YYYY-MM-DD') + '.pdf';
        saveAs(
          config.API.BASE_URI + 'manuals/downloadPdf/' + id,
          outputName
        );
      }, 0);
      return false;
    }
  });



  const editorConfig = useMemo(() => ({
    events: {
      afterInit: (instance) => {
        if (context.user && context.user.role && context.user.role == 'ADMIN') {

          // document.body.classList.add('loading-indicator');

          const contextMenu = new ContextMenu(instance);
          instance.events.on(instance.editor, 'contextmenu', event => {

            const contextMenuOptions = [ ];

            if (manual.main) {
              contextMenuOptions.push(
                {
                  title: 'Add Page Break',
                  icon: 'valign',
                  exec: () => {
                    const rootElem = getRootElement(event.target);
                    const div = document.createElement('div');
                    div.innerHTML = 'PAGE-BREAK';
                    div.classList.add('pagebreak');
                    rootElem.parentNode.insertBefore(div, rootElem.nextSibling);
                    instance.synchronizeValues();
                    instance.setEditorValue();
                  }
                });
                contextMenuOptions.push({
                  title: 'Add Block',
                  icon: 'plus',
                  exec: (e, i) => {
                    const sel = instance.selection.sel;
                    if (sel && sel.rangeCount > 0) {
                      let range = sel.getRangeAt(0);

                      if (hasClass(range.commonAncestorContainer, 'custom-addendum-block')) {
                        alert('You can\'t add a block inside an other block');
                        return;
                      }

                      let selectedContent = range.extractContents();

                      let newDiv = document.createElement('div');
                      newDiv.setAttribute('class', 'custom-addendum-block');

                      newDiv.appendChild(selectedContent);

                      const div = document.createElement('div');
                      div.className = 'custom-addendum-block-id';
                      div.innerHTML = nanoid();
                      newDiv.appendChild(div)


                      range.insertNode(newDiv);

                      range.setStartAfter(newDiv);
                      range.setEndAfter(newDiv);

                      sel.removeAllRanges();
                      sel.addRange(range);

                      const olElems = getRootElement(newDiv).getElementsByTagName('ol');
                      Array.from(olElems).forEach((olElem) => {
                        console.log(olElem);
                        const liElems = olElem.getElementsByTagName('li');
                        if (liElems.length <= 1) {
                          olElem.remove();
                        }
                      });

                      instance.synchronizeValues();
                      instance.setEditorValue();
                    }
                  }
                });
                contextMenuOptions.push({
                  title: 'Remove Block',
                  icon: 'bin',
                  exec: () => {
                    const rootElem = getRootElement(event.target);
                    rootElem.querySelectorAll('.custom-addendum-block').forEach(el => el.classList.remove('custom-addendum-block'));
                    rootElem.querySelectorAll('.custom-addendum-block-id').forEach(el => el.remove());
                  }
                });
            } else {
              contextMenuOptions.push({
                title: 'Add Addendum',
                icon: 'ok',
                exec: () => {

                  let rootElem = event.target;
                  let blockIdNode = rootElem.getElementsByClassName('custom-addendum-block-id');
                  if (blockIdNode.length == 0) {
                    rootElem = getRootElement(event.target);
                    blockIdNode = rootElem.getElementsByClassName('custom-addendum-block-id');
                  }
                  if (blockIdNode.length == 0) {
                    alert('Must define the block in the Main Policy Manual before adding an Addendum');
                    // instance.message.error('Hellow world!');
                  } else if (blockIdNode.length == 1) {
                    let alreadyHasAddendum = false;
                    const blockId = blockIdNode[0].innerText;

                    const elems = document.getElementsByClassName('addendum');
                    Array.from(elems).forEach((el) => {
                      if (blockId == el.getAttribute('data-block')) {
                        alreadyHasAddendum = true;
                      }
                    });

                    if (!alreadyHasAddendum) {
                      // let html = `<div data-block="${blockId}" class="addendum"><p>Enter an Addendum here...</p></div>`;
                      const date = moment().format('MMMM DD, YYYY HH:mm:ss');
                      // const date = new Date().toISOString().split('T')[0];
                      let html = `<div data-block="${blockId}" class="addendum">
                        <div style="width:100%;" class="addendum-meta-data">
                          <div class="addendum-to" style="float:left;text-align:left;color:#AD882F;font-size:15px;">Addendum to</div>
                          <div style="float:right;text-align:right;color:#B3B4B0;font-size:12px;">` + date + ` by <strong>${context.user.name}</strong></div>
                          <div style="clear:both;"></div>
                        </div>
                        <p>Enter an Addendum here...</p>
                      </div>`;

                      rootElem.insertAdjacentHTML('afterend', html);
                      instance.synchronizeValues();
                      instance.setEditorValue();
                    } else {
                      alert('The block has an Addendum. Edit it instead');
                    }
                  }
                }
              });
              contextMenuOptions.push({
                title: 'Remove Addendum',
                // icon: 'cancel',
                icon: 'bin',
                exec: () => {
                  const addendumElem = event.target.closest('.addendum');
                  if (addendumElem) {
                    if (window.confirm('Are you sure you want to delete the addendum?')) {
                      addendumElem.remove();
                      instance.synchronizeValues();
                      instance.setEditorValue();
                    }
                  } else {
                    alert('The block is not an Addendum');
                  }
                }
              });
            }
            contextMenu.show(event.clientX, event.clientY, contextMenuOptions);
            return false; // prevent default context menu
          });
        }
      }
    },
    readonly: (context.user && context.user.role && context.user.role == 'USER' ? true : false),
    activeButtonsInReadOnly: ['< Back', 'PDF'],
    placeholder: '',
    defaultActionOnPaste: 'insert_as_html',
    defaultLineHeight: 1.5,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    enter: 'p',
    font: {
      list: {
        'Roboto Medium,Arial,sans-serif': 'Roboto'
      }
    },

    buttons: toolbarButtons,
    buttonsXS: toolbarButtons,
    buttonsMD: toolbarButtons,
    buttonsSM: toolbarButtons,
    removeButtons: showBlocks ? ['Show Blocks'] : ['Hide Blocks'],
    disablePlugins: [
      'powered-by-jodit',
      'classSpan'
    ],
    }), [ manual, showBlocks ],
  );


	return (
    <div className='lg:w-[1000px] mx-auto'>
      { manual && <div className='lg:w-[1000px] mx-auto text-center p-2 text-xl font-bold'>{ manual.name }</div> }
      { manual && <JoditEditor
        ref={editor}
        value={manual.text}
        config={editorConfig}
        tabIndex={1} // tabIndex of textarea
        // onBlur={newContent => contentUpdated(newContent)} // preferred to use only this option to update the content for performance reasons
        // onChange={newContent => {setContent(newContent)}}
      /> }
    </div>
	);
};