import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import useApi from '../hooks/UseApi';
import Api from '../utils/Api';
import headerSlice, { showNotification } from '../features/common/headerSlice';
import config from '../config/config';


// First, create the thunk
// const fetchUserById = createAsyncThunk(
//   'users/fetchByIdStatus',
//   async (userId: number, thunkAPI) => {
//     const response = await userAPI.fetchById(userId)
//     return response.data
//   },
// )

/*
/// api.js
export const fetchLocations = async (path, page) => {
  return axios({
    method: 'GET',
    url: config.API.BASE_URI + path + '?_page=' + page,
    // url: config.API.BASE_URI + 'users?_page=1',
  }).then(response => response
  ).catch(err => err);
  // const { get, post, updating } = useApi();

  // return get('users', { page: 1 }).then(r => {
  //   console.log(r);
  //   return r.data;
  //   // setData(r.data);
  // })

};


export const post = async (path, data) => {
  return axios({
    method: 'POST',
    url: config.API.BASE_URI + path,
    data: data,
  }).then(response => response.data
  ).catch(err => err);
  // const { get, post, updating } = useApi();

  // return get('users', { page: 1 }).then(r => {
  //   console.log(r);
  //   return r.data;
  //   // setData(r.data);
  // })

};

*/


export const getManuals = createAsyncThunk('manuals/list', async (page) => {
  const response = await Api.apiGet('manuals', page);
  return { data: response.data, totalRows: response.headers['x-paging-total-rows'] };
});

export const saveManuals = createAsyncThunk('manuals/save', async (data) => {
  // console.log(data)
  // if (data.image) {
  //   data.image = data.image.replace("C:\\fakepath\\", "");
  // }

  const response = await Api.apiPost('manuals', data);
  if (data.id) {
    return data;
  } else {
    return response;
  }
});

export const deleteManual = createAsyncThunk('manuals/delete', async ({id, index}) => {
  const response = await Api.apiDelete('manuals/' + id);
  return {id, index};
});


// export const deleteManual = createAsyncThunk('manuals/delete', async (data) => {
//   const response = await Api.apiGet('manuals', page);
//   return { data: response.data, totalRows: response.headers['x-paging-total-rows'] };

//   // const response = await delete('users', data);
//   // console.log(response)
//   // return response;
// });


// export const userSlice = createSlice({
//     name: 'header',
//     initialState: {
//       isLoading: false,
//       users : []
//   },

//     // initialState: {
//     //     pageTitle: "Home",  // current page title state management
//     //     noOfNotifications : 15,  // no of unread notifications
//     //     newNotificationMessage : "",  // message of notification to be shown
//     //     newNotificationStatus : 1,   // to check the notification type -  success/ error/ info
//     // },
//     reducers: {
//         setPageTitle: (state, action) => {
//             state.pageTitle = action.payload.title
//         },


//         // removeNotificationMessage: (state, action) => {
//         //     state.newNotificationMessage = ""
//         // },

//         // showNotification: (state, action) => {
//         //     state.newNotificationMessage = action.payload.message
//         //     state.newNotificationStatus = action.payload.status
//         // },
//     },

//     extraReducers: {
//       [getUsers.pending]: state => {
//         state.isLoading = true
//       },
//       [getUsers.fulfilled]: (state, action) => {
//         state.users = action.payload.data
//         state.isLoading = false
//       },
//       [getUsers.rejected]: state => {
//         state.isLoading = false
//       },
//       }
// })

export const manualSlice = createSlice({
  name: 'manuals',
  initialState: {
    isLoading: false,
    manuals: [],
    totalRows: 0
  },
  reducers: {


    addNewManual: (state, action) => {
          let {newLeadObj} = action.payload
          console.log(newLeadObj)
          // state.users = [...state.users, newLeadObj]
          // post('users', data).then(response => {
          //   console.log(response)
          //   // return response;
          // });


          // dispatch(showNotification({message : "New Lead Added!", status : 1}))
      },

      // deleteManual: async (state, action) => {
      //     let {id, index} = action.payload
      //     console.log('index', index)
      //     console.log('state.manuals', state.manuals)
      //     state.manuals.splice(index, 1)

      //     const response = await Api.apiDelete('manuals/' + id);
      //     console.log(response)

      // }
  },

  extraReducers: {
    [getManuals.pending]: state => {
      state.isLoading = true
    },
    [getManuals.fulfilled]: (state, action) => {
      console.log(action.payload)
      state.manuals = action.payload.data;
      state.totalRows = action.payload.totalRows;
      state.isLoading = false;
    },
    [getManuals.rejected]: state => {
      state.isLoading = false
    },

    [saveManuals.pending]: state => {
      state.isLoading = true
    },
    [saveManuals.fulfilled]: (state, action) => {
      if (action.payload.created_at && action.payload.updated_at && action.payload.created_at == action.payload.updated_at) {
        state.manuals.push(action.payload);
      } else {
        for (let k in state.manuals) {
          if (state.manuals[k].id == action.payload.id) {
            state.manuals[k] = action.payload;
            break;
          }
        }
      }
      state.isLoading = false
    },
    [saveManuals.rejected]: state => {
      state.isLoading = false
    },

    [deleteManual.pending]: state => {
      state.isLoading = true
    },
    [deleteManual.fulfilled]: (state, action) => {
      let {index} = action.payload
      console.log('action.payload', action.payload)
      console.log('index', index)
      console.log('state.manuals', state.manuals)
      state.manuals.splice(index, 1)
    },
    [deleteManual.rejected]: state => {
      state.isLoading = false
    },

  }
})

// export const { setPageTitle } = userSlice.actions

// export default userSlice.reducer
export const { addNewManual } = manualSlice.actions

export default manualSlice.reducer